.homeThree {
  // margin-right: 1rem;
  //max-height: calc(100vh - 9.8rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 2rem;

  .informationsMonney {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    padding: 0 1rem;

    .inputInformation {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      background-color: var(--secondary-color);
      border-radius: 0.5rem;
      margin: 0 0 1rem;
      padding: 1rem;
      width: 100%;

      .inputItem {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        label {
          color: var(--white);
          font-size: 1.2rem;
        }

        img {
          width: 24px;
          height: auto;
        }
      }

      .value {
        color: var(--active-color);
        font-size: 1.3rem;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    background: var(--secondary-color);
    padding: 0 1rem;

    .upperLinks {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      padding-top: 1rem;
      gap: 0.1rem;

      .upperLinksButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 1rem;
        background-color: var(--yellow-color);
        img {
          height: 2rem;
          width: 2rem;
        }
        p {
          color: var(--white);
        }
      }
    }

    .informations {
      display: grid;
      grid-template-columns: 42% 41% 15%;
      padding: 1rem;
      gap: 1.5rem;

      padding-top: 4rem;
    }

    .titleInformation {
      display: flex;
      align-items: center;
      gap: 1rem;

      background-color: var(--background-color-notice);
      background-image: var(--event-list-gradient);
      border-radius: 0.5rem;

      color: var(--white);
      font-size: 1.7rem;

      margin: 2rem 1rem;
      padding: 1rem;
      width: 42%;
      //width: 100%;

      img {
        max-width: 2rem;
        max-height: 2rem;
        object-fit: cover;
      }

      .more {
        color: var(--color-more);
        margin-left: auto;
        cursor: pointer;

        svg {
          height: 1rem;
          width: auto;
        }
      }

      svg {
        font-size: 2rem;
      }

      span {
        color: var(--yellow-color);
        font-size: 1.4rem;
      }
    }

    // .items {
    //   display: grid;
    //   grid-template-columns: repeat(8, 1fr);
    // }

    .items {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .cardsCasino {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: 1rem;
      }
    }

    .bannersHome {
      display: grid;

      //grid-template-columns: repeat(3, 1fr);
      grid-template-columns: repeat(var(--banners-grid-column), 1fr);
      gap: 1rem;

      padding-top: 4rem;

      .bannerItem {
        position: relative;
        height: 100%;

        .classBannerImg {
          width: 100%;
          height: 100%;

          border: 1px solid transparent;
          border-radius: 0.5rem;

          cursor: pointer;
          transition: all 0.35s ease;

          &:hover {
            border: 1px solid var(--yellow-color);
          }
        }

        .bannerOverlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          justify-content: space-between;
          padding: 1rem 0;
          display: none;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          background: rgba(0, 0, 0, 0.8);

          .overLayImg {
            height: 4rem;
            width: auto;

            img {
              height: 100%;
              width: 100%;
            }
          }

          .overLayText {
            font-size: 30px;
            margin-top: 10px;
            color: white;
            font-family: pcp;
          }

          .overLayButton {
            background: #f90;
            display: flex;
            padding: 2px 10px;
            border-radius: 20px;
            color: #000;
          }
        }

        &:hover {
          .bannerOverlay {
            display: var(--overlay-display, none);
          }
        }
      }
    }

    .sponsors {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 1rem;

      padding-top: 4rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: 0.5rem;
      }
    }

    .sponsorsOverride {
      @extend .sponsors;
      display: flex;
    }

    .bannersLinks {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      padding-bottom: 4rem;
      padding: 0 4rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1600px) {
  .homeThree {
    .content {
      .bannersHome {
        display: none;
      }

      .informations {
        grid-template-columns: 40% 40% 17%;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .homeThree {
    overflow-y: hidden;
    // height: calc(100dvh - 12rem);

    .content {
      padding: 0rem 1rem 8rem 1rem;

      .informations {
        grid-template-columns: 1fr;
      }

      // .items {
      //   grid-template-columns: repeat(2, 1fr);
      // }

      .bannersHome {
        display: none;
      }

      .sponsors {
        display: none;
      }

      .sponsorsOverride {
        @extend .sponsors;
      }

      .bannersGames {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        margin: 1rem 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bannersLinks {
        padding-bottom: 0;
      }
    }
  }
}
